import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { ReactComponent as SVGLogo } from "../../../../assets/logo.svg";

const Logo = ({
  areMainLinksVisible,
  className,
  loginHeader,
  url,
  redirect,
}) => (
  <a
    className={clsx("Logo__wrapper", className, { areMainLinksVisible })}
    href={redirect ? redirect : "/"}
  >
    {loginHeader ? (
      <img src={url} alt="Producers National" height="50px" />
    ) : (
      <SVGLogo className="Logo" href={redirect} />
    )}
  </a>
);

export default Logo;
