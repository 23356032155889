import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import Input from "../../../../Input/Input";
import Button from "../../../../Button/Button";

const PolicyFormBlock = ({ as, className, data, headerAs, ...rest }) => {
  const Tag = useMemo(() => as || "section", [as]);
  return (
    <Tag className={clsx("PolicyFormBlock__wrapper", className)} {...rest}>
      <Container>
        <div className="PolicyFormBlock__content">
          {data.header && (
            <h2 className="PolicyFormBlock__header">{data.header}</h2>
          )}
          {data.subheader && (
            <h2 className="PolicyFormBlock__sub__header">{data.subheader}</h2>
          )}
          <div className="PolicyFormBlock__divider__wrapper">
            <div className="PolicyFormBlock__divider" />
          </div>
        </div>
      </Container>
    </Tag>
  );
};

export default PolicyFormBlock;
