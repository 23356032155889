
const toEmail = process.env.REACT_APP_MAIL_IMAGERIGHT_TO_SEND;
const toAgentEmail = process.env.REACT_APP_MAIL_TO_AGENT;
const fromEmail = process.env.REACT_APP_MAIL_FROM;

const getTablePersonalClaim = (data) => {
    return `
    <p>Hello claims team,</p>
    <p>You receieved a new contact form submission. Details below.</p>
    <p>For Testing Email to: ${toEmail}</p>
    <table style="border-collapse: collapse; width: 100%; height: 222px;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107); height: 37px;" colspan="2"><span style="font-size: 24pt;">Reporter Information</span></td>
    </tr>
    <tr>
    <td>Reporter First Name</td>
    <td>${data.reporterFirstName ? data.reporterFirstName : ""}</td>
    </tr>
    <tr>
    <td>Reporter Last Name</td>
    <td>${data.reporterLastName ? data.reporterLastName : ""}</td>
    </tr>
    <tr>
    <td>Is Insured by Us</td>
    <td>${data.insured ? data.insured : ""}</td>
    </tr>
    <tr>
    <td>Reporter Type</td>
    <td>${data.insuredType ? data.insuredType : ""}</td>
    </tr>
    <tr>
    <td>If not insured by Us</td>
    <td>${data.notInsuredType ? data.notInsuredType : ""}<br>${data.notInsuredTypeOther ? data.notInsuredTypeOther : ""}</td>
    </tr>
    <tr>
    <td>Phone Number</td>
    <td>${data.reporterPhone ? data.reporterPhone : ""}</td>
    </tr>
    <tr>
    <td>Email Address</td>
    <td>${data.reporterEmail ? data.reporterEmail : ""}</td>
    </tr>
    </tbody>
    </table>
    <table style="border-collapse: collapse; width: 100%;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107); height: 37px;" colspan="2"><span style="font-size: 24pt;">Photo Estimating Information</span></td>
    </tr>
    <tr>
    <td>Can Contact Reporter</td>
    <td>Yes</td>
    </tr>
    <tr>
    <td>Contact Phone Number</td>
    <td>${data.reporterPhone ? data.reporterPhone : ""}</td>
    </tr>
    <tr>
    <td>Contact Email Address</td>
    <td>${data.reporterEmail ? data.reporterEmail : ""}</td>
    </tr>
    </tbody>
    </table>
    <table style="border-collapse: collapse; width: 100%; height: 222px;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107); height: 37px;" colspan="2"><span style="font-size: 24pt;">Loss Details Information</span></td>
    </tr>
    <tr>
    <td>Loss Date</td>
    <td>${data.dateOfAccident ? data.dateOfAccident : ""}</td>
    </tr>
    <tr>
    <td>Loss Time</td>
    <td>${data.timeOfAccident ? data.timeOfAccident : ""}</td>
    </tr>
    <tr>
    <td>Address of Loss</td>
    <td>${data.locationOfAccidentStreetAddress ? data.locationOfAccidentStreetAddress : ""}</td>
    </tr>
    <tr>
    <td>City of Loss</td>
    <td>${data.locationOfAccidentCity ? data.locationOfAccidentCity : ""}</td>
    </tr>
    <tr>
    <td>State of Loss</td>
    <td>${data.locationOfAccidentState ? data.locationOfAccidentState : ""}</td>
    </tr>
    <tr>
    <td>Zip Code of Loss</td>
    <td>${data.locationOfAccidentZip ? data.locationOfAccidentZip : ""}</td>
    </tr>
    <tr>
    <td>Loss Type</td>
    <td>${data.damageType ? data.damageType : ""}</td>
    </tr>
    <tr>
    <td>Loss Description</td>
    <td>${data.accidentDescription ? data.accidentDescription : ""}</td>
    </tr>
    <tr>
    <td>Additional Comments</td>
    <td>${data.additionalComments ? data.additionalComments : ""}</td>
    </tr>
    <tr>
    <td>Were Police Notified</td>
    <td>${data.policeNotified ? data.policeNotified : ""}</td>
    </tr>
    <tr>
    <td>Police Case Number</td>
    <td>${data.policeReportNumber ? data.policeReportNumber : ""}</td>
    </tr>
    <tr>
    <td>Police Department Name</td>
    <td>${data.policeDepartment ? data.policeDepartment : ""}</td>
    </tr>
    <tr>
    <td>Police Report Method</td>
    <td>${data.reportFiled ? data.reportFiled : ""}</td>
    </tr>
    </tbody>
    </table>
    <table style="border-collapse: collapse; width: 100%; height: 222px;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107); height: 37px;" colspan="2"><span style="font-size: 24pt;">Insured Vehicle Information</span></td>
    </tr>
    <tr>
    <td>Insured Policy Number</td>
    <td>${data.policyNumber ? data.policyNumber : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Year</td>
    <td>${data.vehicleYear ? data.vehicleYear : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Make</td>
    <td>${data.vehicleMake ? data.vehicleMake : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Model</td>
    <td>${data.vehicleModel ? data.vehicleModel : ""}</td>
    </tr>
    <tr>
    <td>Vehicle VIN Number</td>
    <td>${data.vehicleVinNumber ? data.vehicleVinNumber : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Color</td>
    <td>${data.vehicleColor ? data.vehicleColor : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Use Type</td>
    <td>${data.vehicleDriversPurposeOfUse ? data.vehicleDriversPurposeOfUse : ""}</td>
    </tr>
    <tr>
    <td>Is Vehicle Damaged?</td>
    <td>${data.isVehicleDamaged ? data.isVehicleDamaged : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Damage Description</td>
    <td>${data.vehicleDamageDescription ? data.vehicleDamageDescription : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Unrelated Prior Damage</td>
    <td>${data.unrelatedPriorDamage ? data.unrelatedPriorDamage : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Impact Location</td>
    <td>${data.pointOfImpact ? data.pointOfImpact : ""}</td>
    </tr>
    <tr>
    <td>Vehicle License Plate</td>
    <td>${data.licensePlateNumber ? data.licensePlateNumber : ""}</td>
    </tr>
    <tr>
    <td>Is the vehicle drivable?</td>
    <td>${data.isVehicleDrivable ? data.isVehicleDrivable : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage Location Street</td>
    <td>${data.vehicleLocationStreetAddress ? data.vehicleLocationStreetAddress : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage City</td>
    <td>${data.vehicleLocationCity ? data.vehicleLocationCity : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage State</td>
    <td>${data.vehicleLocationState ? data.vehicleLocationState : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage Zip</td>
    <td>${data.vehicleLocationZip ? data.vehicleLocationZip : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage Name</td>
    <td>${data.businessName ? data.businessName : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage Phone Number</td>
    <td>${data.businessPhone ? data.businessPhone : ""}</td>
    </tr>
    </tbody>
    </table>
    <table style="border-collapse: collapse; width: 100%; height: 222px;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107); height: 37px;" colspan="2"><span style="font-size: 24pt;">Insured Vehicle Owner Information</span></td>
    </tr>
    <tr>
    <td>Vehicle Owner First Name</td>
    <td>${data.policyHolderFirstName ? data.policyHolderFirstName : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Owner Last Name</td>
    <td>${data.policyHolderLastName ? data.policyHolderLastName : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Owner Street Address</td>
    <td>${data.policyHolderStreetAddress ? data.policyHolderStreetAddress : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Owner City</td>
    <td>${data.policyHolderCity ? data.policyHolderCity : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Owner State</td>
    <td>${data.policyHolderState ? data.policyHolderState : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Owner Zip</td>
    <td>${data.policyHolderZip ? data.policyHolderZip : ""}</td>
    </tr>
    <tr>
    <td>Home Phone</td>
    <td>${data.policyHolderHomePhone ? data.policyHolderHomePhone : ""}</td>
    </tr>
    <tr>
    <td>Cell Phone</td>
    <td>${data.policyHolderCellPhone ? data.policyHolderCellPhone : ""}</td>
    </tr>
    <tr>
    <td>Work Phone</td>
    <td>${data.policyHolderWorkPhone ? data.policyHolderWorkPhone : ""}</td>
    </tr>
    <tr>
    <td>Email</td>
    <td>${data.policyHolderEmail ? data.policyHolderEmail : ""}</td>
    </tr>
    </tbody>
    </table>
    <table style="border-collapse: collapse; width: 100%; height: 222px;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107); height: 37px;" colspan="2"><span style="font-size: 24pt;">Vehicle Driver Information</span></td>
    </tr>
    <tr>
    <td>Driver Same as Vehicle Owner</td>
    <td>${data.sameAsVehicleOwner ? data.sameAsVehicleOwner : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver First Name</td>
    <td>${data.vehicleDriversFirstName ? data.vehicleDriversFirstName : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Last Name</td>
    <td>${data.vehicleDriversLastName ? data.vehicleDriversLastName : ""}</td>
    </tr>
    <tr>
    <td>Home Phone</td>
    <td>${data.vehicleDriversHomePhone ? data.vehicleDriversHomePhone : ""}</td>
    </tr>
    <tr>
    <td>Cell Phone</td>
    <td>${data.vehicleDriversCellPhone ? data.vehicleDriversCellPhone : ""}</td>
    </tr>
    <tr>
    <td>Work Phone</td>
    <td>${data.vehicleDriversWorkPhone ? data.vehicleDriversWorkPhone : ""}</td>
    </tr>
    <tr>
    <td>Email</td>
    <td>${data.vehicleDriversEmail ? data.vehicleDriversEmail : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver License Number</td>
    <td>${data.vehicleDriversLicenseNumber ? data.vehicleDriversLicenseNumber : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver License State</td>
    <td>${data.vehicleDriversLicenseState ? data.vehicleDriversLicenseState : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Street Address</td>
    <td>${data.vehicleDriversStreetAddress ? data.vehicleDriversStreetAddress : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver City</td>
    <td>${data.vehicleDriversCity ? data.vehicleDriversCity : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver State</td>
    <td>${data.vehicleDriversState ? data.vehicleDriversState : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Zip</td>
    <td>${data.vehicleDriversZip ? data.vehicleDriversZip : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Was Injured</td>
    <td>${data.wasDriverInjured ? data.wasDriverInjured : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Injury Description</td>
    <td>${data.driverInjuryDescription ? data.driverInjuryDescription : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Sought Medical Attention</td>
    <td>${data.didDriverSeekMedical ? data.didDriverSeekMedical : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Received Ticket</td>
    <td>${data.didDriverReceiveTicket ? data.didDriverReceiveTicket : ""}</td>
    </tr>
    </tbody>
    </table>
    <p>${data.passengerTable0 ? data.passengerTable0 : ""} ${data.passengerTable1 ? data.passengerTable1 : ""} ${data.passengerTable2 ? data.passengerTable2 : ""} ${data.passengerTable3 ? data.passengerTable3 : ""}</p>
    <table style="border-collapse: collapse; width: 100%; height: 222px;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107); height: 37px;" colspan="2"><span style="font-size: 24pt;">Claimant Vehicle Information</span></td>
    </tr>
    <tr>
    <td>Is Another Vehicle Involved?</td>
    <td>${data.isAnotherVehicleInvolved ? data.isAnotherVehicleInvolved : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Year</td>
    <td>${data.otherVehicleYear ? data.otherVehicleYear : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Make</td>
    <td>${data.otherVehicleMake ? data.otherVehicleMake : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Model</td>
    <td>${data.otherVehicleModel ? data.otherVehicleModel : ""}</td>
    </tr>
    <tr>
    <td>Vehicle VIN Number</td>
    <td>${data.otherVehicleVehicleVin ? data.otherVehicleVehicleVin : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Color</td>
    <td>${data.otherVehicleVehicleColor ? data.otherVehicleVehicleColor : ""}</td>
    </tr>
    <!-- <tr>
                <td>Vehicle Use Type</td>
                <td>${data.vehicleDriversPurposeOfUse ? data.vehicleDriversPurposeOfUse : ""}</td>
            </tr> -->
    <tr>
    <td>Is Vehicle Damaged?</td>
    <td>${data.isOtherVehicleDamaged ? data.isOtherVehicleDamaged : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Damage Description</td>
    <td>${data.otherVehicleDamageDescription ? data.otherVehicleDamageDescription : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Unrelated Prior Damage</td>
    <td>${data.otherVehicleUnrelatedPriorDamage ? data.otherVehicleUnrelatedPriorDamage : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Impact Location</td>
    <td>${data.otherVehicleInitialPointOfImpact ? data.otherVehicleInitialPointOfImpact : ""}</td>
    </tr>
    <tr>
    <td>Vehicle License Plate</td>
    <td>${data.otherVehicleLicensePlateNumber ? data.otherVehicleLicensePlateNumber : ""}</td>
    </tr>
    <tr>
    <td>Is the vehicle drivable?</td>
    <td>${data.isOtherVehicleDrivable ? data.isOtherVehicleDrivable : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage Location Street</td>
    <td>${data.otherVehicleLocationStreetAddress ? data.otherVehicleLocationStreetAddress : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage City</td>
    <td>${data.otherVehicleLocationCity ? data.otherVehicleLocationCity : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage State</td>
    <td>${data.otherVehicleLocationState ? data.otherVehicleLocationState : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage Zip</td>
    <td>${data.otherVehicleLocationZip ? data.otherVehicleLocationZip : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage Name</td>
    <td>${data.OtherVehicleBusinessName ? data.OtherVehicleBusinessName : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Garage Phone Number</td>
    <td>${data.OtherVehicleBusinessPhone ? data.OtherVehicleBusinessPhone : ""}</td>
    </tr>
    </tbody>
    </table>
    <table style="border-collapse: collapse; width: 100%; height: 222px;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107); height: 37px;" colspan="2"><span style="font-size: 24pt;">Claimant Vehicle Owner Information</span></td>
    </tr>
    <tr>
    <td>Vehicle Owner First Name</td>
    <td>${data.otherVehicleOwnerFirstName ? data.otherVehicleOwnerFirstName : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Owner Last Name</td>
    <td>${data.otherVehicleOwnerLastName ? data.otherVehicleOwnerLastName : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Owner Street Address</td>
    <td>${data.otherVehicleOwnerStreetAddress ? data.otherVehicleOwnerStreetAddress : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Owner City</td>
    <td>${data.otherVehicleOwnerCity ? data.otherVehicleOwnerCity : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Owner State</td>
    <td>${data.otherVehicleOwnerState ? data.otherVehicleOwnerState : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Owner Zip</td>
    <td>${data.otherVehicleOwnerZip ? data.otherVehicleOwnerZip : ""}</td>
    </tr>
    <tr>
    <td>Home Phone</td>
    <td>${data.otherVehicleOwnerHomePhone ? data.otherVehicleOwnerHomePhone : ""}</td>
    </tr>
    <tr>
    <td>Cell Phone</td>
    <td>${data.otherVehicleOwnerCellPhone ? data.otherVehicleOwnerCellPhone : ""}</td>
    </tr>
    <tr>
    <td>Work Phone</td>
    <td>${data.otherVehicleOwnerWorkPhone ? data.otherVehicleOwnerWorkPhone : ""}</td>
    </tr>
    <tr>
    <td>Email</td>
    <td>${data.otherVehicleOwnerEmail ? data.otherVehicleOwnerEmail : ""}</td>
    </tr>
    <tr>
    <td>Was Vehicle Owner Injured?</td>
    <td>${data.otherVehicleDriversInjured ? data.otherVehicleDriversInjured : ""}</td>
    </tr>
    <tr>
    <td>Is Other Vehicle Driver the Same as the Owner</td>
    <td>${data.otherVehicleDriversSameAsVehicleOwner ? data.otherVehicleDriversSameAsVehicleOwner : ""}</td>
    </tr>
    <tr>
    <td>Sought Medical Attention?</td>
    <td>${data.otherVehicleDriversDidSeekMedical ? data.otherVehicleDriversDidSeekMedical : ""}</td>
    </tr>
    <tr>
    <td>Injury Description</td>
    <td>${data.otherVehicleDriversInjuryDescription ? data.otherVehicleDriversInjuryDescription : ""}</td>
    </tr>
    <tr>
    <td>Did Receive Ticker</td>
    <td>${data.otherVehicleDriversDidReceiveTicket ? data.otherVehicleDriversDidReceiveTicket : ""}</td>
    </tr>
    <tr>
    <td>Reason for Ticket</td>
    <td>${data.otherVehicleDriversTicketReason ? data.otherVehicleDriversTicketReason : ""}</td>
    </tr>
    <tr>
    <td>Drivers License Number</td>
    <td>${data.otherVehicleDriversLicenseNumber ? data.otherVehicleDriversLicenseNumber : ""}</td>
    </tr>
    <tr>
    <td>Drivers License State</td>
    <td>${data.otherVehicleDriversLicenseState ? data.otherVehicleDriversLicenseState : ""}</td>
    </tr>
    </tbody>
    </table>
    <table style="border-collapse: collapse; width: 100%; height: 222px;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107); height: 37px;" colspan="2"><span style="font-size: 24pt;">Claimant Vehicle Driver Information</span></td>
    </tr>
    <tr>
    <td>Driver Same as Vehicle Owner</td>
    <td>${data.otherVehicleDriversSameAsVehicleOwner ? data.otherVehicleDriversSameAsVehicleOwner : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver First Name</td>
    <td>${data.otherVehicleDriversFirstName ? data.otherVehicleDriversFirstName : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Last Name</td>
    <td>${data.otherVehicleDriversLastName ? data.otherVehicleDriversLastName : ""}</td>
    </tr>
    <tr>
    <td>Home Phone</td>
    <td>${data.otherVehicleDriversHomePhone ? data.otherVehicleDriversHomePhone : ""}</td>
    </tr>
    <tr>
    <td>Cell Phone</td>
    <td>${data.otherVehicleDriversCellPhone ? data.otherVehicleDriversCellPhone : ""}</td>
    </tr>
    <tr>
    <td>Work Phone</td>
    <td>${data.otherVehicleDriversWorkPhone ? data.otherVehicleDriversWorkPhone : ""}</td>
    </tr>
    <tr>
    <td>Email</td>
    <td>${data.otherVehicleDriversEmail ? data.otherVehicleDriversEmail : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver License Number</td>
    <td>${data.vehicleDriversLicenseNumber ? data.vehicleDriversLicenseNumber : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver License State</td>
    <td>${data.vehicleDriversLicenseState ? data.vehicleDriversLicenseState : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Street Address</td>
    <td>${data.otherVehicleDriversStreetAddress ? data.otherVehicleDriversStreetAddress : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver City</td>
    <td>${data.otherVehicleDriversCity ? data.otherVehicleDriversCity : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver State</td>
    <td>${data.otherVehicleDriversState ? data.otherVehicleDriversState : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Zip</td>
    <td>${data.otherVehicleDriversZip ? data.otherVehicleDriversZip : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Date of Birth</td>
    <td>${data.otherVehicleDriversDateOfBirth ? data.otherVehicleDriversDateOfBirth : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Was Injured</td>
    <td>${data.otherVehicleDriversInjured ? data.otherVehicleDriversInjured : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Injury Description</td>
    <td>${data.otherVehicleDriversInjuryDescription ? data.otherVehicleDriversInjuryDescription : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Sought Medical Attention</td>
    <td>${data.otherVehicleDriversDidSeekMedical ? data.otherVehicleDriversDidSeekMedical : ""}</td>
    </tr>
    <tr>
    <td>Vehicle Driver Received Ticket</td>
    <td>${data.otherVehicleDriversDidReceiveTicket ? data.otherVehicleDriversDidReceiveTicket : ""}</td>
    </tr>
    <tr>
    <td>Ticket Reason</td>
    <td>${data.otherVehicleDriversTicketReason ? data.otherVehicleDriversTicketReason : ""}</td>
    </tr>
    </tbody>
    </table>
    <p>${data.passengerClaimantTable0 ? data.passengerClaimantTable0 : ""} ${data.passengerClaimantTable1 ? data.passengerClaimantTable1 : ""} ${data.passengerClaimantTable2 ? data.passengerClaimantTable2 : ""} ${data.passengerClaimantTable3 ? data.passengerClaimantTable3 : ""}</p>
    <table style="border-collapse: collapse; width: 100%;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107);" colspan="2"><span style="font-size: 24pt;">Pedestrian Information</span></td>
    </tr>
    <tr>
    <td>First Name</td>
    <td>${data.pedestriansFirstName ? data.pedestriansFirstName : ""}</td>
    </tr>
    <tr>
    <td>Last Name</td>
    <td>${data.pedestriansLastName ? data.pedestriansLastName : ""}</td>
    </tr>
    <tr>
    <td>Pedestrian Address</td>
    <td>${data.pedestriansStreetAddress ? data.pedestriansStreetAddress : ""}</td>
    </tr>
    <tr>
    <td>Pedestrian City</td>
    <td>${data.pedestriansCity ? data.pedestriansCity : ""}</td>
    </tr>
    <tr>
    <td>Pedestrian State</td>
    <td>${data.pedestriansState ? data.pedestriansState : ""}</td>
    </tr>
    <tr>
    <td>Pedestrian Zip</td>
    <td>${data.pedestriansZip ? data.pedestriansZip : ""}</td>
    </tr>
    <tr>
    <td>Pedestrian DOB</td>
    <td>${data.pedestrianDateOfBirth ? data.pedestrianDateOfBirth : ""}</td>
    </tr>
    <tr>
    <td>Pedestrian Injured?</td>
    <td>${data.pedestriansInjured ? data.pedestriansInjured : ""}</td>
    </tr>
    <tr>
    <td>Pedestrian Injury Description</td>
    <td>${data.pedestriansInjuryDescription ? data.pedestriansInjuryDescription : ""}</td>
    </tr>
    <tr>
    <td>Pedestrian Sought Medical Attention</td>
    <td>${data.pedestrianDidSeekMedical ? data.pedestrianDidSeekMedical : ""}</td>
    </tr>
    <tr>
    <td>Home Phone</td>
    <td>${data.pedestriansHomePhone ? data.pedestriansHomePhone : ""}</td>
    </tr>
    <tr>
    <td>Cell Phone</td>
    <td>${data.pedestriansCellPhone ? data.pedestriansCellPhone : ""}</td>
    </tr>
    <tr>
    <td>Work Phone</td>
    <td>${data.pedestriansWorkPhone ? data.pedestriansWorkPhone : ""}</td>
    </tr>
    <tr>
    <td>Email</td>
    <td>${data.pedestriansEmail ? data.pedestriansEmail : ""}</td>
    </tr>
    </tbody>
    </table>
    <table style="border-collapse: collapse; width: 100%;" border="1"><colgroup> <col style="width: 50%;"> <col style="width: 50%;"> </colgroup>
    <tbody>
    <tr>
    <td style="background-color: rgb(45, 194, 107);" colspan="2"><span style="font-size: 24pt;">Fixed Object Information</span></td>
    </tr>
    <tr>
    <td>Was Fixed Object Involved?</td>
    <td>${data.fixedObjectInvolved ? data.fixedObjectInvolved : ""}</td>
    </tr>
    <tr>
    <td>Fixed Object Owner First Name</td>
    <td>${data.fixedObjectOwnersFirstName ? data.fixedObjectOwnersFirstName : ""}</td>
    </tr>
    <tr>
    <td>Fixed Object Owner Last Name</td>
    <td>${data.fixedObjectOwnersLastName ? data.fixedObjectOwnersLastName : ""}</td>
    </tr>
    <tr>
    <td>Fixed Object Owner Street Address</td>
    <td>${data.fixedObjectOwnersStreetAddress ? data.fixedObjectOwnersStreetAddress : ""}</td>
    </tr>
    <tr>
    <td>Fixed Object Owner City</td>
    <td>${data.fixedObjectOwnersCity ? data.fixedObjectOwnersCity : ""}</td>
    </tr>
    <tr>
    <td>Fixed Object Owner State</td>
    <td>${data.fixedObjectOwnersState ? data.fixedObjectOwnersState : ""}</td>
    </tr>
    <tr>
    <td>Fixed Object Owner Zip</td>
    <td>${data.fixedObjectOwnersZip ? data.fixedObjectOwnersZip : ""}</td>
    </tr>
    <tr>
    <td>Fixed Object Owner Phone</td>
    <td>${data.fixedObjectOwnersHomePhone ? data.fixedObjectOwnersHomePhone : ""}</td>
    </tr>
    <tr>
    <td>Fixed Object Owner Email</td>
    <td>${data.fixedObjectOwnersEmail ? data.fixedObjectOwnersEmail : ""}</td>
    </tr>
    <tr>
    <td>Reporter's Contact Email</td>
    <td>${data.contactEmail ? data.contactEmail : ""}</td>
    </tr>
    <tr>
    <td>Description of Fixed Object</td>
    <td>${data.fixedObjectDescription ? data.fixedObjectDescription : ""}</td>
    </tr>
    <tr>
    <td>Description of Fixed Object Damage</td>
    <td>${data.fixedObjectDamage ? data.fixedObjectDamage : ""}</td>
    </tr>
    </tbody>
</table>`
}

const getTableContactForm = (data) => {
    return `
        <p>Hello ${data.subject} team,</p>
        <p>You receieved a new contact form submission. Details below.</p>
        <p>For Testing Email to: ${data.emailTo}</p>
        <table style="border-collapse: collapse; width: 100%; height: 129.5px;" border="1">
            <colgroup>
                <col style="width: 49.9387%;">
                <col style="width: 49.9387%;">
            </colgroup>
            <tbody>
                <tr style="height: 18.5px;">
                    <td style="height: 18.5px;">Name</td>
                    <td style="height: 18.5px;">${data.name}</td>
                </tr>
                <tr style="height: 18.5px;">
                    <td style="height: 18.5px;">Subject</td>
                    <td style="height: 18.5px;">${data.subject}</td>
                </tr>
                <tr style="height: 18.5px;">
                    <td style="height: 18.5px;">Email</td>
                    <td style="height: 18.5px;">${data.email}</td>
                </tr>
                <tr style="height: 18.5px;">
                    <td style="height: 18.5px;">Phone Number</td>
                    <td style="height: 18.5px;">${data.phone}</td>
                </tr>
                <tr style="height: 18.5px;">
                    <td style="height: 18.5px;">Policy/Claim Number</td>
                    <td style="height: 18.5px;">${data.policyOrClaimNumber}</td>
                </tr>
                <tr style="height: 18.5px;">
                    <td style="height: 18.5px;">Message</td>
                    <td style="height: 18.5px;">${data.summary}</td>
                </tr>
            </tbody>
        </table>
        `
}

const getTableAgentForm = (data) => {
    return `
    <p>Hello Brandy,</p>
    <p>You have a new agent application for Stonegate Insurance:</p>
    <table style="border-collapse: collapse; width: 100%; height: 129.5px;" border="1">
        <colgroup>
            <col style="width: 49.9387%;">
            <col style="width: 49.9387%;">
        </colgroup>
        <tbody>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Agency Name:</td>
                <td style="height: 18.5px;">${data.agencyName}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Primary Agent Contact Name:</td>
                <td style="height: 18.5px;">${data.name}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Phone Number</td>
                <td style="height: 18.5px;">${data.phone}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Email:</td>
                <td style="height: 18.5px;">${data.email}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Lines of Business</td>
                <td style="height: 18.5px;">${data.linesBusiness}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Total Annual Premium</td>
                <td style="height: 18.5px;">${data.annualPremium}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">How did you hear about us?:</td>
                <td style="height: 18.5px;">${data.origin}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">States They Operate In:</td>
                <td style="height: 18.5px;">${data.states}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Physical Office Location Address:</td>
                <td style="height: 18.5px;">${data.physicalAddress}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Website:</td>
                <td style="height: 18.5px;">${data.website}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Office/Landline Phone Number:</td>
                <td style="height: 18.5px;">${data.officePhone}</td>
            </tr>
        </tbody>
    </table>
`
}

const getClaimNumberEmail = (data) => {
    return `<p>Greetings,</p>
    <p>Thank you for contacting Stonegate Insurance. We have received your claim and will be in touch shortly.</p>
    <p>For your records, </p>
    <h1> Your claim number is: ${data.claimNumber}</h1>
    `
}

const getContentToEmail = (data) => {
    if (data.form === 'contact') {
        return getTableContactForm(data)
    } else if (data.form === 'agent') {
        return getTableAgentForm(data)
    } else if (data.claimNumber !== undefined) {
        return getClaimNumberEmail(data)
    } else {
        return getTablePersonalClaim(data)
    }
}

const sendEmailTo = (data) => {
    if (data.claimNumber) {
        return data.reporterEmail
    } else if (data.form === 'contact') {
        return data.emailTo
    } else if (data.form === 'agent') {
        return toAgentEmail
    } else {
        return toEmail
    }
}

const getSubject = (data) => {
    return data.form == "contact" ?
        "Contact Form " + data.subject :
        data.form == "agent" ?
            "New Agent Application for Stonegate Insurance" :
            "New Personal Auto Claim"
}

const getEmailData = (data) => {
    return {
        from: fromEmail,
        to: sendEmailTo(data),
        subject: getSubject(data),
        html: getContentToEmail(data)
    }
}

export { getEmailData }