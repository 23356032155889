import React, { useMemo,useEffect } from 'react';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { ReactComponent as SVGlogo } from '../../assets/logo.svg';
import { ReactComponent as BBBLogo } from '../../assets/BBB-Logo.svg';
import { ReactComponent as KBRALogo } from '../../assets/kbra-logo.svg';
import CompanyInfo from '../CompanyInfo/CompanyInfo';
import DividerBlock from '../PageBlocks/components/ContentBlocks/DividerBlock/DividerBlock';
import CookiePolicy from '../CookiePolicy/CookiePolicy';

const Footer = ({ className, companyInfo, footer }) => {
  const year = useMemo(() => new Date().getFullYear(), []);
  const pageLinks = useMemo(() => {
    const data = get(footer, 'page_links.data');
    return data || [];
  }, [footer]);

  return (
    <footer className={clsx('Footer__wrapper', className)}>
      <DividerBlock />
      <div className="Footer">
        <Container>
          <Link
            className="Footer__logo__wrapper"
            to="/"
          >
            <SVGlogo className="Footer__logo img-fluid" />
          </Link>

          <div className="Footer__columns">
            <a target="_blank" href="https://www.bbb.org/us/il/niles/profile/insurance-companies/stonegate-insurance-company-0654-88480551#bbbseal"><BBBLogo className="Footer__accreditation" /></a>
            <a target="_blank" href="https://unique-insurance-media.s3.us-east-2.amazonaws.com/KBRA_Insurance_Overview_2022_Arrow_a8d174e225.pdf"><KBRALogo className="Footer__accreditation" /></a>
          </div>

          <CompanyInfo companyInfo={companyInfo} />

          <div className="Footer__pageLinks">
            {pageLinks.map((link) => (
              <Link
                className="Footer__pageLink"
                key={link.id}
                to={`/${link.attributes.slug}`}
              >
                {link.attributes.pageName}
              </Link>
            ))}
            <span>
              Copyright {year}
            </span>
          </div>
        </Container>
      </div>
      <div>
        <CookiePolicy />
      </div>
    </footer>
  );
};

export default Footer;
