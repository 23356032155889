import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import Select from '../../../../../../../../Select/Select';
import usStates from '../../../us_states.json';
import useScreen from '../hooks/useScreen';

import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import { max as maxLength } from './schema';
import Textarea from '../../../../../../../../Textarea/Textarea';

const Screen = ({ form, screenIndex, wizard }) => {
  
  const otherVehicleDriversSameAsVehicleOwner = form?.watch('otherVehicleDriversSameAsVehicleOwner');
  const otherVehicleDriversDidReceiveTicket = form?.watch('otherVehicleDriversDidReceiveTicket');
  const otherVehicleDriversInjured = form?.watch('otherVehicleDriversInjured');
  const emailValidation = form?.formState.errors.otherVehicleDriversEmail
  const workPhoneValidation = form?.formState.errors.otherVehicleDriversWorkPhone
  const homePhoneValidation = form?.formState.errors.otherVehicleDriversHomePhone
  const cellPhoneValidation = form?.formState.errors.otherVehicleDriversCellPhone
  const dateOfBirthValidation = form?.formState.errors.otherVehicleDriversDateOfBirth

  const isInvalid = useMemo(
    () => {
      if (!otherVehicleDriversSameAsVehicleOwner) return true;
      if (otherVehicleDriversSameAsVehicleOwner === "No") {
        if (emailValidation || workPhoneValidation || homePhoneValidation || cellPhoneValidation || dateOfBirthValidation) return true;
      } else {
        if (dateOfBirthValidation) return true;
      }
      return false;
    }
    ,
    [
      otherVehicleDriversSameAsVehicleOwner,
      emailValidation,
      workPhoneValidation,
      homePhoneValidation,
      cellPhoneValidation,
      dateOfBirthValidation
    ],
  );

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <Fieldset legend="Other Vehicle Driver Information
">
        <Row>
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Same as Vehicle Owner?"
              name="otherVehicleDriversSameAsVehicleOwner"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          {otherVehicleDriversSameAsVehicleOwner === "No" &&
            (
              <>
                <Row>
                  <Col xs={12} sm={6} md={4}>
                    <Input
                      autoComplete="off"
                      error={form?.formState.errors.otherVehicleDriversFirstName}
                      id="otherVehicleDriversFirstName"
                      label="First name"
                      touched={form?.formState.touchedFields.otherVehicleDriversFirstName}
                      {...form?.register('otherVehicleDriversFirstName', {
                        onBlur: () => form?.trigger('otherVehicleDriversFirstName'),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <Input
                      autoComplete="off"
                      id="otherVehicleDriversMiddleName"
                      label="Middle name"
                      {...form?.register('otherVehicleDriversMiddleName')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <Input
                      autoComplete="off"
                      error={form?.formState.errors.otherVehicleDriversLastName}
                      id="otherVehicleDriversLastName"
                      label="Last name"
                      touched={form?.formState.touchedFields.otherVehicleDriversLastName}
                      {...form?.register('otherVehicleDriversLastName', {
                        onBlur: () => form?.trigger('otherVehicleDriversLastName'),
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={12}>
                    <Input
                      autoComplete="off"
                      id="otherVehicleDriversStreetAddress"
                      label="Street address"
                      {...form?.register('otherVehicleDriversStreetAddress')}
                    />
                  </Col>
                  <Col xs={12} sm={4}>
                    <Input
                      autoComplete="off"
                      id="otherVehicleDriversCity"
                      label="City"
                      {...form?.register('otherVehicleDriversCity')}
                    />
                  </Col>
                  <Col xs={12} sm={4}>
                    <Select
                      autoComplete="off"
                      id="otherVehicleDriversState"
                      label="State"
                      {...form?.register('otherVehicleDriversState')}
                    >
                      <option disabled value="" />
                      {usStates.map((state) => (
                        <option
                          key={state}
                          value={state}
                        >
                          {state}
                        </option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={12} sm={4}>
                    <Input
                      autoComplete="off"
                      id="otherVehicleDriversZip"
                      label="Zip Code"
                      maxLength={10}
                      touched={form?.formState.touchedFields.otherVehicleDriversZip}
                      error={form?.formState.errors.otherVehicleDriversZip}
                      {...form?.register('otherVehicleDriversZip', {
                        onChange: () => form?.trigger('otherVehicleDriversZip'),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      id="otherVehicleDriversHomePhone"
                      label="Home phone number"
                      type="tel"
                      touched={form?.formState.touchedFields.otherVehicleDriversHomePhone}
                      error={form?.formState.errors.otherVehicleDriversHomePhone}
                      {...form?.register('otherVehicleDriversHomePhone', {
                        onChange: () => form?.trigger('otherVehicleDriversHomePhone'),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      id="otherVehicleDriversCellPhone"
                      label="Cell phone number"
                      type="tel"
                      touched={form?.formState.touchedFields.otherVehicleDriversCellPhone}
                      error={form?.formState.errors.otherVehicleDriversCellPhone}
                      {...form?.register('otherVehicleDriversCellPhone', {
                        onChange: () => form?.trigger('otherVehicleDriversCellPhone'),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      id="otherVehicleDriversWorkPhone"
                      label="Work phone number"
                      type="tel"
                      touched={form?.formState.touchedFields.otherVehicleDriversWorkPhone}
                      error={form?.formState.errors.otherVehicleDriversWorkPhone}
                      {...form?.register('otherVehicleDriversWorkPhone', {
                        onChange: () => form?.trigger('otherVehicleDriversWorkPhone'),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      autoComplete="off"
                      error={form?.formState.errors.otherVehicleDriversEmail}
                      id="otherVehicleDriversEmail"
                      label="Email"
                      touched={form?.formState.touchedFields.otherVehicleDriversEmail}
                      type="email"
                      {...form?.register('otherVehicleDriversEmail', {
                        onBlur: () => form?.trigger('otherVehicleDriversEmail'),
                      })}
                    />
                  </Col>

                </Row>
              </>
            )}
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Input
              autoComplete="off"
              error={form?.formState.errors.otherVehicleDriversDateOfBirth}
              id="otherVehicleDriversDateOfBirth"
              touched={form?.formState.touchedFields.otherVehicleDriversDateOfBirth}
              label="Date of birth"
              type="date"
              max={new Date().toISOString().split('T')[0]}
              min="1900-01-01"
              {...form?.register('otherVehicleDriversDateOfBirth', {
                onChange: () => form?.trigger('otherVehicleDriversDateOfBirth'),
              })}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              autoComplete="off"
              id="otherVehicleDriversLicenseNumber"
              label="Drivers License Number"
              {...form?.register('otherVehicleDriversLicenseNumber')}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Select
              autoComplete="off"
              id="otherVehicleDriversLicenseState"
              label="Drivers License State"
              {...form?.register('otherVehicleDriversLicenseState')}
            >
              <option disabled value="" />
              {usStates.map((state) => (
                <option
                  key={state}
                  value={state}
                >
                  {state}
                </option>
              ))}
            </Select>
          </Col>
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Did this person receive a ticket?"
              name="otherVehicleDriversDidReceiveTicket"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
              <span value="Unknown">Unknown</span>
            </RadioGroup>
          </Col>
          {otherVehicleDriversDidReceiveTicket === "Yes" &&
            <Col xs={12} sm={12}>
              <Textarea
                id="otherVehicleDriversTicketReason"
                label="Ticket Reason"
                maxLength={maxLength}
                name="otherVehicleDriversTicketReason"
                rows={3}
                form={form}
                {...form?.register('otherVehicleDriversTicketReason')}
              />

            </Col>}
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Was this person injured?"
              name="otherVehicleDriversInjured"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          {otherVehicleDriversInjured === "Yes" &&
            (
              <Row>
                <Col xs={12} sm={6}>
                  <Textarea
                    id="otherVehicleDriversInjuryDescription"
                    label="Describe Injuries"
                    maxLength={maxLength}
                    name="otherVehicleDriversInjuryDescription"
                    rows={3}
                    form={form}
                    {...form?.register('otherVehicleDriversInjuryDescription')}
                  />

                </Col>
                <Col xs={12} sm={6}>
                  <RadioGroup
                    form={form}
                    label="Did they seek medical treatment?"
                    name="otherVehicleDriversDidSeekMedical"
                  >
                    <span value="Yes">Yes</span>
                    <span value="No">No</span>
                  </RadioGroup>
                </Col>
              </Row>
            )}

        </Row>

      </Fieldset>
    </>
  );
};

export default Screen;
