import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import { Accordion, Container, Col } from 'react-bootstrap';
import useIsBlockVisible from '../../../hooks/useIsBlockVisible';
import DividerBlock from '../../ContentBlocks/DividerBlock/DividerBlock';
import FaqAccordionBlock from '../FaqAccordionBlock/FaqAccordionBlock';

const ProductBlock = ({
    as,
    className,
    data,
    headerAs,
    ...rest
}) => {
    const isVisible = useIsBlockVisible(data, ['products']);
    const [activeKey, setActiveKey] = useState('')
    window.addEventListener('hashchange', changeActiveKey);
    function changeActiveKey() {
        setActiveKey(window.location.hash.split('#')[1])
    };

    if (!isVisible) return null;

    return (
        <Container>
            <div className="ProductBlock">
                <Accordion className="ProductBlock__accordion" activeKey={activeKey} >
                    {data.products?.data.map(({ attributes, id }) => (

                        <Accordion.Item
                            className="ProductBlock__accordion__item"
                            id={attributes.productSlug}
                            key={attributes.productSlug}
                            eventKey={attributes.productSlug}
                            data-aos="fade-up"
                            data-aos-once="true"
                        >

                            <Accordion.Header className="ProductBlock__accordion__header"
                                onClick={() => {
                                    activeKey === attributes.productSlug ? setActiveKey('') :
                                        setActiveKey(attributes.productSlug)
                                }}>
                                <h2>{attributes.header}</h2>
                            </Accordion.Header>

                            <Accordion.Body className="ProductBlock__accordion__body">

                                <DividerBlock />

                                <div className="ProductBlock__first_section product__section">
                                    <h3>{attributes.firstHeader}</h3>
                                    <Markdown
                                        options={{ forceBlock: true }}>
                                        {attributes.firstBody}
                                    </Markdown>
                                    <h4>{attributes.firstSubHeader}</h4>
                                    <div className="ProductBlock__policy__jackets row">
                                        {attributes.policies.map(({ header, id, pdf, url }) => (
                                            <Col xs="auto">
                                                <a
                                                    className="ProductBlock__policy__link"
                                                    key={id}
                                                    href={url ? url : pdf?.data?.attributes?.url}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    {header}
                                                </a>
                                            </Col>
                                        ))}
                                    </div>
                                </div>

                                <DividerBlock />

                                <div className='ProductBlock__second_section product__section'>
                                    <h3>{attributes.secondHeader}</h3>
                                    <Markdown
                                        options={{ forceBlock: true }}>
                                        {attributes.secondBody ? attributes.secondBody : ""}
                                    </Markdown>
                                </div>

                                <DividerBlock />

                                <div className="ProductBlock__third_section product__section">
                                    <h3>{attributes.thirdHeader}</h3>
                                    {attributes.faqs.length > 0 && (
                                        <FaqAccordionBlock
                                            data={attributes}
                                            hideHeader={true}
                                        />)}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </Container>
    );
};

export default ProductBlock;
