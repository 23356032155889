import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import useScreen from '../hooks/useScreen';

const Screen = ({ form, screenIndex, wizard }) => {
  const vehicleYear = form?.watch('vehicleYear');
  const vehicleMake = form?.watch('vehicleMake');
  const vehicleModel = form?.watch('vehicleModel');

  const isInvalid = useMemo(
    () => {
      if (!vehicleYear || !vehicleMake || !vehicleModel) return true;
      return false;
    },
    [
      vehicleYear,
      vehicleMake,
      vehicleModel,
    ],
  );

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <Fieldset legend="Stonegate&rsquo;s Insured Vehicle Information">
      <Row>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="vehicleYear"
            label="Vehicle year"
            error={form?.formState.errors.vehicleYear}
            touched={form?.formState.touchedFields.vehicleYear}
            {...form?.register('vehicleYear', {
              onBlur: () => form?.trigger('vehicleYear'),
              required: 'This field is required.',
            })}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="vehicleMake"
            label="Vehicle make"
            error={form?.formState.errors.vehicleMake}
            touched={form?.formState.touchedFields.vehicleMake}
            {...form?.register('vehicleMake', {
              onBlur: () => form?.trigger('vehicleMake'),
              required: 'This field is required.',
            })}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="vehicleModel"
            label="Vehicle model"
            error={form?.formState.errors.vehicleModel}
            touched={form?.formState.touchedFields.vehicleModel}
            {...form?.register('vehicleModel', {
              onBlur: () => form?.trigger('vehicleModel'),
              required: 'This field is required.',
            })}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="vehicleColor"
            label="Vehicle color"
            {...form?.register('vehicleColor')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="licensePlateNumber"
            label="License plate number"
            {...form?.register('licensePlateNumber')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="vehicleVinNumber"
            label="Vehicle VIN Number"
            maxLength={17}
            touched={form?.formState.touchedFields.vehicleVinNumber}
            error={form?.formState.errors.vehicleVinNumber}
            {...form?.register('vehicleVinNumber', {
              onBlur: () => form?.trigger('vehicleVinNumber'),
            })}
          />
        </Col>
      </Row>
    </Fieldset>
  );
};

export default Screen;
