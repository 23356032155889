import React, { useMemo } from 'react';
import DividerBlock from './components/ContentBlocks/DividerBlock/DividerBlock';
import HeaderParagraphBlock from './components/ContentBlocks/HeaderParagraphBlock/HeaderParagraphBlock';
import TextColumnsBlock from './components/ContentBlocks/TextColumnsBlock/TextColumnsBlock';
import ClaimFormBlock from './components/FormBlocks/ClaimFormBlock/ClaimFormBlock';
import ContactFormBlock from './components/FormBlocks/ContactFormBlock/ContactFormBlock';
import GlobalHeroBlock from './components/HeroBlocks/GlobalHeroBlock';
import HomeHeroBlock from './components/HeroBlocks/HomeHeroBlock';
import ImageTitleBlock from './components/ImageBlocks/ImageTitleBlock/ImageTitleBlock';
import SliderBlock from './components/ImageBlocks/SliderBlock/SliderBlock';
import TileBlock from './components/ImageBlocks/TileBlock/TileBlock';
import MapBlock from './components/ContentBlocks/MapBlock/MapBlock';
import TextWithFactsBlock from './components/ContentBlocks/TextWithFactsBlock/TextWithFactsBlock';
import ProductBlock from './components/AccordionBlocks/ProductBlock/ProductBlock';
import FaqAccordionBlock from './components/AccordionBlocks/FaqAccordionBlock/FaqAccordionBlock';
import InfographicBlock from './components/ImageBlocks/InfographicBlock/InfographicBlock';
import AgentFormBlock from './components/FormBlocks/AgentFormBlock/AgentFormBlock';
import HeaderButtonBlock from './components/ContentBlocks/HeaderButtonBlock/HeaderButtonBlock';
import PolicyFormBlock from './components/ContentBlocks/PolicyFormBlock/PolicyFormBlock';
import PaymentFormBlock from './components/FormBlocks/PaymentFormBlock/PaymentFormBlock';
import ButtonLoginOptionsBlock from './components/ContentBlocks/ButtonLoginOptionsBlock/ButtonLoginOptionsBlock';
import FindLinksBlock from './components/ContentBlocks/FindLinksBlock/FindLinksBlock';

const blockComponents = {
  'content-block.divider-block': DividerBlock,
  'content-block.header-paragraph-block': HeaderParagraphBlock,
  'content-block.header-button-block': HeaderButtonBlock,
  'content-block.policy-form': PolicyFormBlock,
  'content-block.button-login-options-block': ButtonLoginOptionsBlock,
  'content-block.find-links-block': FindLinksBlock,
  'content-block.text-columns-block': TextColumnsBlock,
  'content-block.map-block': MapBlock,
  'content-block.text-with-facts': TextWithFactsBlock,
  'accordion-block.product-block': ProductBlock,
  'accordion-block.faq-accordion-block': FaqAccordionBlock,
  'form-block.contact-form-block': ContactFormBlock,
  'form-block.agent-form-block': AgentFormBlock,
  'form-block.claim-form-block': ClaimFormBlock,
  'form-block.payment-form-block': PaymentFormBlock,
  'hero-block.global-hero-block': GlobalHeroBlock,
  'hero-block.home-hero-block': HomeHeroBlock,
  'image-block.slider-block': SliderBlock,
  'image-block.tile-block': TileBlock,
  'image-block.image-title-block': ImageTitleBlock,
  'image-block.infographic-block': InfographicBlock,
};

const PageBlocks = ({ pageBlocks, mapDatas }) => {
  const blocks = useMemo(
    () => (
      pageBlocks
        .map(({ __component: name, ...data }) => {
          const BlockComponent = blockComponents[name];
          if (!BlockComponent) return null;

          const id = `${name}-${data.id}`;
          const className = name.substr(name.indexOf('.') + 1);
          // const isMapBlock = name === 'content-block.map-block';

          return {
            BlockComponent,
            className,
            data,
            id,
            mapDatas
            
          };
        })
        .filter(Boolean)
    ),
    [pageBlocks],
  );

  return (
    <div className="PageBlocks">
      {blocks.map(({
        BlockComponent,
        className,
        data,
        id,
        mapDatas
      }, i) => (
        <BlockComponent
          className={className}
          data={data}
          data-aos="fade-up"
          data-aos-once="true"
          data-index={i}
          id={id}
          key={id}
          mapDatas={mapDatas}
        />
      ))}
    </div>
  );
};

export default PageBlocks;
