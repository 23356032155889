import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import Select from '../../../../../../../../Select/Select';
import useScreen from '../hooks/useScreen';
import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import { max as maxLength } from './schema';
import Textarea from '../../../../../../../../Textarea/Textarea';

const Screen = ({ form, screenIndex, wizard }) => {

  const policeNotified = form?.watch('policeNotified');
  const accidentDescription = form?.watch('accidentDescription');

  const isInvalid = useMemo(
    () => (
      !policeNotified ||
      !accidentDescription
    ),
    [
      policeNotified,
      accidentDescription
    ],
  );

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <Fieldset legend="Stonegate Driver Information">
        <Row>
          <Col xs={12} sm={12}>
            <Textarea
              id="accidentDescription"
              label="Describe what happened in the accident"
              maxLength={maxLength}
              name="accidentDescription"
              rows={3}
              form={form}
              {...form?.register('accidentDescription', {
                onBlur: () => form?.trigger('accidentDescription'),
                required: 'You must describe the accident.',
              })}
            />
          </Col>
          <Col xs={12} sm={12}>
            <RadioGroup
              form={form}
              label="Were the police notified?"
              name="policeNotified"
              required
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          {policeNotified === "Yes" &&
            (
              <>
                <Col xs={12} sm={6} md={4}>
                  <Input
                    autoComplete="off"
                    id="policeDepartment"
                    label="Police Department"
                    {...form?.register('policeDepartment')}
                  />
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Input
                    autoComplete="off"
                    id="policeReportNumber"
                    label="Police Report Number"
                    {...form?.register('policeReportNumber')}
                  />
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Select
                    id="reportFiled"
                    label="Where was the report filed?"
                    {...form?.register('reportFiled')}
                  >
                    <option disabled value="">Choose one</option>
                    <option value="ON_SCENE">Police On Scene</option>
                    <option value="IN_STATION">Report Filed in Station</option>
                    <option value="OVER_PHONE">Report Filed on Phone</option>
                  </Select>
                </Col>
              </>
            )}
          <Col xs={12} sm={12}>
            <Textarea
              id="additionalComments"
              label="Any Additional Comments?"
              maxLength={maxLength}
              name="additionalComments"
              rows={3}
              form={form}
              {...form?.register('additionalComments')}
            />
          </Col>
        </Row>

      </Fieldset>
    </>
  );
};

export default Screen;
