import React, { useState } from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { Container, Row, Col } from 'react-bootstrap';
import useHeaderAs from '../../../hooks/useHeaderAs';
import useIsBlockVisible from '../../../hooks/useIsBlockVisible';
import PolicyForm from './components/PolicyForm';
import Markdown from 'markdown-to-jsx';
import Helpers from '../../../../../utils/Helpers';

const PaymentFormBlock = ({ data, className, ...rest }) => {
  // TODO: wire up strapi to return options for `white`, `black`
  const [ref, headerAs] = useHeaderAs('h1', 'h2');
  const isVisible = useIsBlockVisible(data, ['header', 'displayForm']);
  const insuredPortalUrl = process.env.REACT_APP_WEBSITE_URL + "/insured-mh-logins";

  if (!isVisible) return;
  return (
    <section
      className={clsx('PaymentFormBlock__wraper', className)}
      ref={ref}
      {...rest}
    >
      <Container>
        <div className="PaymentFormBlock">
          {data.header && (
            <div className="PaymentFormBlock__header__wrapper">
              {headerAs && (() => {
                const Header = headerAs;
                return (
                  <Header className="PaymentFormBlock__header">
                    {parse(data.header)}
                  </Header>
                );
              })()}
            </div>
          )}
          {data.body && (
            <Markdown
              className="PaymentFormBlock__body"
              options={{ forceBlock: true }}
            >
              {Helpers.preventOrphans(data.body)}
            </Markdown>
          )}
          <Row className="PaymentFormBlock__body" >
            <p className="PaymentFormBlock__small__warning__text">Any payments processed after 10:00 pm CST may be posted to your policy the next business day and would be subject to a late fee.</p>
          </Row>
          {data.displayForm && <PolicyForm className="PaymentFormBlock__form" />}
          <Row className="justify-content-center">
            <Col xs={12} md={5}>
              <a
                className="Button Button__Grey PaymentFormBlock__button"
                href="https://policyholder.stonegateins.com/login"
                target="_blank"
              >
                Auto Login
              </a>
            </Col>
            <Col xs={12} md={5} className="mt-sm-5 mt-5 mt-md-0">
              <a
                className="Button Button__Grey PaymentFormBlock__button"
                href={insuredPortalUrl}
                target="_blank"
              >
                Manufactured Home Login
              </a>
            </Col>
          </Row>
          <Col xs={12}>
            <p className="PaymentFormBlock__small__text">*The payment balance may include an installment fee and any late payment fees if applicable. A $25 fee will be assessed for any checks returns for insufficient funds. Payment administered by Stonegate Insurance Managers</p>
          </Col>

        </div>
      </Container>
    </section>
  );
};

export default PaymentFormBlock;
